export const environment = {
  COMPANY_NAME: 'evollo',
  APP_NAME: 'ADMIN',
  production: false,
  apiUrl: "https://saas-account.api.evollo.cloud",
  qualityApiUrl: "https://saas-quality.api.evollo.cloud",
  enabledChat: false,
  apps: {
    id: "https://eva.id.evollo.com.br/",
    quality: "https://eva.insighthub.evollo.com.br",
  },
	features: {
		enabledChat: false,
		enableOrgCreation: true,
		enableSupport: false,
		enableFaq: false,
    enableMicrosoftLogin: true
	}
};
